import { Box, BoxProps, Flex, Text } from "@chakra-ui/core";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const ConservationStatusLabel: React.FC<BoxProps & {
  type: string;
  value: string;
}> = ({ type, value, ...props }) => (
  <Box
    mt={2}
    mr={2}
    display="inline-block"
    borderColor="gray.200"
    borderWidth="1px"
    rounded="lg"
    py={1}
    px={3}
    {...props}
  >
    <Flex>
      <a
        target="_new"
        href={`https://australianmuseum.net.au/conservation-status-what-does-it-mean#${type.toLowerCase()}`}
      >
        <Text mr={2} color="primary.orange">
          <FontAwesomeIcon icon={faInfo} />
        </Text>
      </a>
      <Text fontWeight="semibold" mr={2}>
        {type}:{" "}
      </Text>
      <Text>{value}</Text>
    </Flex>
  </Box>
);

export default ConservationStatusLabel;
