import { Box, BoxProps, Flex } from "@chakra-ui/core";
import React from "react";
import AudioPlayButton from "./AudioPlayButton";

interface Props {
  audio: {
    id: string;
    url: string;
    credits: string;
  };
}

const FrogCallPlayer: React.FC<BoxProps & Props> = ({
  audio: { id, url, credits },
  ...props
}) => {
  return (
    <Flex
      alignItems="center"
      mb={3}
      borderColor="gray.100"
      borderWidth="1px"
      rounded="lg"
      p={3}
      {...props}
    >
      <AudioPlayButton url={ensureHttps(url)} />
      <Box {...props}>
        <Caption>By: {credits}</Caption>
      </Box>
    </Flex>
  );
};

const ensureHttps = (url: string) => url.replace("http://", "https://");

const Caption: React.FC<BoxProps> = props => (
  <Box fontStyle="italic" fontSize="md" as="p" {...props} />
);

export default FrogCallPlayer;
