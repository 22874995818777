import { Box, BoxProps, Flex } from "@chakra-ui/core";
import moment from "moment";
import React from "react";

interface Props {
  breedingSeason: {
    [key: string]: string;
  };
}

const FrogCallingPeriod: React.FC<Props> = ({ breedingSeason }) => {
  return (
    <Box my={6}>
      <Flex>
        <Legend label="Possible" color="red.500" />
        <Legend label="Yes" color="yellow.500" />
        <Legend label="Peak" color="green.500" />
      </Flex>
      <Flex mt={3}>
        {moment.monthsShort().map(month => (
          <Flex
            key={month}
            width={["1.5rem", "2.0rem", "2.5rem", "2.5rem"]}
            flexDirection="column"
            alignItems="center"
          >
            <Bar value={breedingSeason[month]} />
            <Month>{month}</Month>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};

const Legend: React.FC<BoxProps & { label: string; color: string }> = ({
  label,
  color
}) => {
  return (
    <Flex mr={6} alignItems="center">
      <Box mr={2} rounded="full" width="10px" height="10px" bg={color}></Box>
      <Box>{label}</Box>
    </Flex>
  );
};

const Bar: React.FC<BoxProps & { value: string }> = ({ value, ...props }) => {
  let bg = "gray.500";
  let height = "0rem";

  switch (value) {
    case "no":
      height = "0rem";
      bg = "gray.500";
      break;
    case "possible":
      height = "3rem";
      bg = "red.500";
      break;
    case "yes":
      height = "6rem";
      bg = "yellow.500";
      break;
    case "peak":
      height = "10rem";
      bg = "green.500";
      break;
  }
  return (
    <Flex flexDirection="column" justifyContent="flex-end" height="10rem">
      <Box
        bg={bg}
        height={height}
        rounded="full"
        width={["0.45rem", "0.5rem", "0.7rem", "0.7rem"]}
        {...props}
      />
    </Flex>
  );
};

const Month: React.FC<BoxProps & {}> = props => (
  <Box fontSize={["xs", "sm", "md", "md"]} mt={2} {...props} />
);

export default FrogCallingPeriod;
