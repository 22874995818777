import { Box, BoxProps, Flex, Image, Stack } from "@chakra-ui/core";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { graphql, Link } from "gatsby";
import React from "react";
import ConservationStatusLabel from "../components/ConservationStatusLabel";
import Container from "../components/Container";
import FrogCallingPeriod from "../components/FrogCallingPeriod";
import FrogCallPlayer from "../components/FrogCallPlayer";
import Layout from "../components/Layout";
import SEO from "../components/Seo";

interface Props {
  data: {
    frog: any;
  };
}

const Frog: React.FC<Props> = ({ data: { frog } }) => {
  return (
    <Layout>
      <SEO title={frog.scientific_name} description={frog.description} />
      <Container py={[12, 12, 24, 24]}>
        <Flex pb={8} color="primary.orange" fontWeight="semibold">
          <Box mr={3}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Box>
          <Link to="/frogs">Back to Frogs</Link>
        </Flex>
        <Box
          fontSize={["4xl", "4xl", "5xl", "5xl"]}
          fontWeight="semibold"
          fontStyle="italic"
          as="h1"
        >
          {frog.scientific_name}
        </Box>
        <Box color="gray.500" lineHeight="1.0" fontSize="2xl" as="h2">
          {frog.common_names.join(", ")}
        </Box>
        <Flex mt={6} justifyContent="space-between" flexWrap="wrap">
          <Box width="35rem">
            <Subheader>Conservation Status</Subheader>
            <ConservationStatusLabel
              type="EPBC"
              value={frog.conservation_status.epbc}
            />
            <ConservationStatusLabel
              type="IUCN"
              value={frog.conservation_status.iucn}
            />
            <Subheader>Calling Period</Subheader>
            <FrogCallingPeriod breedingSeason={frog.breeding_season} />
            <Subheader>Description</Subheader>
            <Body dangerouslySetInnerHTML={{ __html: frog.description }} />
            <Subheader>Breeding Biology</Subheader>
            <Body dangerouslySetInnerHTML={{ __html: frog.breeding_biology }} />
            <Subheader>Similar Species</Subheader>
            <Body dangerouslySetInnerHTML={{ __html: frog.similar_species }} />
          </Box>
          <Box width="32rem">
            <Subheader>Images</Subheader>
            <Box mt={3}>
              {frog.images.map(
                (image: { id: string; card: string; photographer: string }) => (
                  <Box key={image.id}>
                    <Image rounded="lg" src={image.card} />
                    <Caption>Photo: {image.photographer}</Caption>
                  </Box>
                )
              )}
            </Box>
            <Subheader>Calls</Subheader>
            <Stack mt={3} isInline flexWrap="wrap" spacing={3}>
              {frog.audios.map(
                (audio: {
                  id: string;
                  url: string;
                  credits: string;
                  location: string;
                }) => (
                  <FrogCallPlayer key={audio.id} width="240px" audio={audio} />
                )
              )}
            </Stack>
            <Subheader>Distribution</Subheader>
            <Body dangerouslySetInnerHTML={{ __html: frog.distribution }} />
            <Image
              mt={3}
              width="100%"
              rounded="lg"
              src={frog.distribution_image}
            />
          </Box>
        </Flex>
      </Container>
    </Layout>
  );
};

export default Frog;

const Subheader: React.FC = props => (
  <Box fontWeight="semibold" fontSize="xl" as="h3" mt={6} {...props} />
);

const Body: React.FC<BoxProps> = props => (
  <Box fontSize="lg" as="p" mt={3} {...props} />
);

const Caption: React.FC<BoxProps> = props => (
  <Box fontStyle="italic" fontSize="md" as="p" mt={1} mb={3} {...props} />
);

export const query = graphql`
  query Frog($slug: String) {
    frog(slug: { eq: $slug }) {
      slug
      scientific_name
      common_names
      is_native
      call_traits
      body_size
      colours
      description
      habitat_description
      habitats
      states
      breeding_biology
      conservation_status {
        iucn
        epbc
      }
      water_bodies
      breeding_season {
        Jan
        Feb
        Mar
        Apr
        May
        Jun
        Jul
        Aug
        Sep
        Oct
        Nov
        Dec
      }
      distribution
      distribution_image
      is_common
      similar_species
      images {
        id
        name
        caption
        photographer
        location
        card
        position
        card
      }
      audios {
        id
        url
        credits
      }
    }
  }
`;
