import { Box } from "@chakra-ui/core";
import {
  faPauseCircle,
  faPlayCircle,
  IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useReducer, useRef } from "react";

interface Props {
  url: string;
}

type State = any;
type Action = { type: "play" } | { type: "pause" } | { type: "stop" };

function reducer(state: State, action: Action) {
  switch (action.type) {
    case "play":
      state.audioRef.current.play();
      return {
        ...state,
        isPlaying: true,
        isPaused: false,
        isStopped: false
      };
    case "pause":
      state.audioRef.current.pause();
      return {
        ...state,
        isPlaying: false,
        isPaused: true,
        isStopped: false
      };
    case "stop":
      state.audioRef.current.pause();
      state.audioRef.current.currentTime = 0;
      return {
        ...initialState,
        audioRef: state.audioRef
      };
    default:
      throw new Error();
  }
}

const initialState: State = {
  isPlaying: false,
  isPaused: false,
  isStopped: true,
  audioRef: null
};

const AudioPlayButton: React.FC<Props> = ({ url }) => {
  const audioRef = useRef(null);
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    audioRef
  });
  const { isPlaying, isPaused, isStopped } = state;

  return (
    <Box mr={3} textAlign="center">
      {(isPaused || isStopped) && (
        <Button
          icon={faPlayCircle}
          onClick={() =>
            dispatch({
              type: "play"
            })
          }
        />
      )}
      {isPlaying && (
        <Button
          icon={faPauseCircle}
          onClick={() =>
            dispatch({
              type: "pause"
            })
          }
        />
      )}
      {isPlaying && (
        <Box
          as="button"
          color="primary.orange"
          onClick={() =>
            dispatch({
              type: "stop"
            })
          }
        >
          Stop
        </Box>
      )}

      <audio ref={audioRef}>
        <source src={url.replace(".wav", ".m4a")} type="audio/mp4" />
      </audio>
    </Box>
  );
};

const Button: React.FC<{ icon: IconDefinition; onClick: () => void }> = ({
  icon,
  onClick
}) => (
  <Box color="primary.orange" fontSize="4xl" as="button" onClick={onClick}>
    <FontAwesomeIcon icon={icon} />
  </Box>
);

export default AudioPlayButton;
